import { useReducer, useContext, createContext } from 'react';

const CommonContext = createContext();
const CommonDispatchContext = createContext();

const reducer = (common, action) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...common, ...action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const CommonProvider = ({ children, initial }) => {
  const [common, dispatch] = useReducer(reducer, initial);
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <CommonDispatchContext.Provider value={dispatch}>
      {/*eslint-disable-next-line react/react-in-jsx-scope*/}
      <CommonContext.Provider value={common}>{children}</CommonContext.Provider>
    </CommonDispatchContext.Provider>
  );
};

export const useCommon = () => useContext(CommonContext);
export const useCommonDispatch = () => useContext(CommonDispatchContext);

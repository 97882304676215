/*
 * Top level app & layout, loaded ONCE at the start
 */
import { useEffect, Suspense } from 'react';
// import { Analytics } from '@vercel/analytics/react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import '../styles/global.scss';

import { CommonProvider } from '@context/common';
import { UserProvider } from '@context/user';
// import { ParallaxProvider } from 'react-scroll-parallax';
// import { request, authRequest, baseRequest } from '@apis/utils';
// import InterceptorProvider from '@hiredigital/ui/InterceptorProvider';
const Helper = dynamic(() => import('@components/Layout/Helper'), { ssr: false });

const pageView = (url) => {
  try {
    const pageEvent = {
      event: 'pageview',
      page: url,
    };
    window && window.dataLayer && window.dataLayer?.push(pageEvent);
  } catch (e) {}
  return;
};

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps, err }) => {
  const router = useRouter();
  // const [pageIsLoading, setLoading] = React.useState(false);
  useEffect(() => {
    const startRouteChange = (url) => {
      // setLoading(true);
    };
    const endRouteChange = (url) => {
      pageView(url);

      // setLoading(false);
    };

    const isBrowser = typeof window !== 'undefined';

    if (isBrowser) {
      router.events.on('routeChangeStart', startRouteChange);
      router.events.on('routeChangeComplete', endRouteChange);
      router.events.on('routeChangeError', endRouteChange);
    }

    return () => {
      router.events.off('routeChangeStart', startRouteChange);
      router.events.off('routeChangeComplete', endRouteChange);
      router.events.off('routeChangeError', endRouteChange);
    };
  }, [router]);

  return (
    <CommonProvider>
      <UserProvider>
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />
        </Head>
        <Component {...pageProps} err={err} />
        <Helper />
        {/*<Analytics />*/}
      </UserProvider>
    </CommonProvider>
  );
};

export default App;
